<template>
    <div class="BrandCenter">
        <!-- <BrandList v-on:clickBrand="clickBrand" v-on:brandListdata="brandListdata" :activeName="activeName" ></BrandList> -->
        <div class="brand">
            <TitleList v-on:clickBrand="clickBrand" v-on:brandListdata="brandListdata" :activeName="activeName" ></titleList>
        </div> 
        <div v-for="(item, index) in brandList2" :key="index" v-if="index == 0 && item.product.length">
            <div class="pattern_bg" v-if="item.pattern">
                <VanImage width="100%" height="auto" lazy-load :src="item.pattern_bg" />
            </div>
        </div>
        <div class="swipercon">
            <div class="hdswiper">
                <img :src="wcj_images_line" />
            </div>
            <swiper class="swiper-wrapper swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
                <swiper-slide v-for="(item, index) in brandList3" :key="index">
                    <router-link :to="'/Tasting/goods/detail?id=' + item.id" class="acea-row row-middle">
                        <div class="pic">
                            <img :src="item.wcj_images" width="100%" class="swiper-lazy">
                        </div>
                        <div class="ename Ellipses1">{{item.en_name}}</div>
                        <div class="name Ellipses1">{{item.abbreviation_name}}</div>
                        <div class="desc Ellipses1">{{item.country}} | {{item.countries}} | {{item.chateau}} | {{item.grape}}</div>
                        <p class="afooter font-color">>>进入详情介绍</p>
                    </router-link>
                </swiper-slide>
                <!--                <div class="swiper-pagination" slot="pagination"></div>-->
            </swiper>
            <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
                <swiper-slide class="slide-1" v-for="(item, index) in brandList3" :key="index">
                    <img :src="item.wcj_images_exh_thumb" width="100%" class="swiper-lazy">
                </swiper-slide>
            </swiper>
        </div>
        <div v-for="(item, index) in brandList2" :key="index" v-if="index > 0 && item.product.length" >
            <div class="branditem">
                <div class="pattern_bg" v-if="item.pattern">
                    <VanImage width="100%" height="auto" lazy-load :src="item.pattern_bg" />
                </div>
                <div class="branditem_hd">
                    <div class="picbg">
                        <VanImage width="100%" height="auto" lazy-load :src="require('@assets/images/brand/brandtitbg.png')" />
                    </div>
                    <div class="pic">
                        <VanImage width="100%" height="auto" lazy-load :src="item.logo" />
                    </div>
                    <div  class="goodslist" >
                        <div class="dpic" :class="sonitem.id==item.pid?'curdpic':''" v-for="(sonitem, sonindex) in item.product" :key="sonindex" @click="product(index, sonindex)">
                            <VanImage width="100%" height="100%" lazy-load fit="cover" :src="sonitem.wcj_images" />
                        </div>
                    </div>
                </div>
                <div class="branditem_bd" >
                    <Swipe ref="SwipeItem" @change="product(index,$event)" :show-indicators="true">
                        <SwipeItem v-for="(sonitem, sonindex) in item.product" :key="sonindex"   >
                            <VanImage width="100%" height="auto" lazy-load :src="sonitem.wcj_images_exh"  />
                        </SwipeItem>
                    </Swipe>
                </div>
                <div style="text-align: center;padding: 0.2rem 0;" @click="goPages('/Tasting/goods/detail?id=' + item.pid)">
                    <div class="branditem_bd_f font-color">查看详细介绍 >></div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { swiper, swiperSlide } from "vue-awesome-swiper";
    import { getBrand, getBrandList } from "@api/tasting";
    import { Toast,Swipe,SwipeItem } from "vant";
    import { Image as VanImage } from 'vant';
    import Backtop from "./components/Backtop";
    import Footer from "./components/Footer";
    import BrandList from "./components/brandList";
    import TitleList from "./components/titleList";
    let vm = null;
    export default {
        name: "BrandCenter",
        data: function() {
            return {
                activeName: 0,
                brandList: [],
                brandList2: [],
                wcj_images_line: '',
                brandList3: [],

                active: 0,
                swiperOptionHd: {
                    loop: false, //是否循环
                    loopedSlides: 5, // looped slides should be the same
                },
                swiperOptionTop: {
                    loop: false, //是否循环
                    loopedSlides: 5, // looped slides should be the same
                    effect : 'coverflow',
                    slidesPerView: 5,
                    centeredSlides: true,
                    spaceBetween: -140,
                    coverflowEffect: {
                        rotate: 0,
                        stretch: 0,
                        depth: 400,
                        modifier: 2,
                        slideShadows : false
                    },
                },
                realIndex: 0,
                swiperOptionThumbs: {
                    loop: false,
                    loopedSlides: 5, // looped slides should be the same
                    spaceBetween: 10,
                    centeredSlides: true,
                    slidesPerView: 'auto',
                    touchRatio: 0.2,
                    slideToClickedSlide: true,
                    on: {
                        slideChange: function(){
                            vm.getrealIndex(this.realIndex);
                        },
                    },
                },
                //indexID:0,
                swiperOptionBg: {
                    loop: false,
                    loopedSlides: 5, // looped slides should be the same
                    spaceBetween: 10,
                    centeredSlides: true,
                    slidesPerView: 'auto',
                    //touchRatio: 0.2,
                    slideToClickedSlide: true,
                    on: {
                        slideChangeTransitionEnd:function(){
                            
                            //console.log(this);
                            
                            vm.setUrl(this.realIndex);
                        },
                        

                    },
                },
            };
        },
        components: {
            BrandList,
            swiper,
            swiperSlide,
            VanImage,
            Backtop, Footer,
            Swipe, SwipeItem,TitleList
        },
        created() {
            vm = this;
            this.$store.state.app.storeid = this.$route.query.store_id;
        },
        mounted: function() {
            this.getBrandListdata();
        },
        methods: {
            goPages: function(url) {
                this.$router.push({ path: url });
            },
            clickBrand: function (id) {
                this.$router.push({ path: '/Tasting/goods/detail?id=' + id });
            },
            brandListdata: function (data) {
                this.activeName=data[0].id;
            },
            getrealIndex: function(realIndex) {
                this.realIndex=realIndex;
                this.wcj_images_line=this.brandList3[realIndex].wcj_images_line;
            },
            product: function(index, sonindex) {
                var px = -(390*sonindex);
                //console.log(sonindex);
                //var test = 'transition-duration';
                vm.$refs.SwipeItem[index].$refs.track.style.transform = 'translateX('+px+'px)';
                //vm.$refs.SwipeItem[index].$refs.track.style.test = '0ms';
                //console.log(vm.$refs.SwipeItem[index].$refs.track.style.transform);
                //vm.$refs.SwipeItem[index].$refs.track.style.transform = 'translateX('+px+'px)';
                this.brandList2[index].pid=this.brandList2[index].product[sonindex].id;
                this.brandList2[index].wcj_image=this.brandList2[index].product[sonindex].wcj_images_exh;
            },
            getBrandListdata: function() {
                Toast.loading({
                    duration: 0,
                    message: '加载中...',
                    forbidClick: true,
                });
                getBrandList({
                    page: 1,
                }).then(res => {
                    Toast.clear();
                    let datalist=res.data;
                    datalist.forEach((item, index)=>{
                        if(item.product.length>0){
                            item.pid=item.product[0].id;
                            item.wcj_image=item.product[0].wcj_images_exh;
                        }
                    })
                    this.brandList2=datalist;
                    if(res.data[0]){
                        this.brandList3=res.data[0].product;
                        if(this.brandList3[0]){
                            this.wcj_images_line=this.brandList3[0].wcj_images_line;
                        }
                    }
                })
                .catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
        },
        watch:{
            brandList3: function() {
                this.$nextTick(() => {
                    const swiperTop = this.$refs.swiperTop.swiper
                    const swiperThumbs = this.$refs.swiperThumbs.swiper
                    swiperTop.controller.control = swiperThumbs
                    swiperThumbs.controller.control = swiperTop
                })
            },
            deep: true,
            
            
        }
    }
</script>

<style scoped lang="scss">
::v-deep {
    .brandList{
        .van-tab{padding:0.3rem 0.1rem;}
        .van-tabs__wrap {
            height: 1.2rem;
        }
        .van-tabs__line{background:none;}
        // .van-tabs__line::before{
        //     background:#f4f4f4;
        //     border-left: 1px solid #b6b6b6;
        //     border-top: 1px solid #b6b6b6;
        //     width: 0.2rem;
        //     height: 0.2rem;
        //     display: block;
        //     content: '';
        //     margin: 0 auto;transform: rotate(45deg);
        // }
    }
}
.BrandCenter{
    .brand{
            width: 6.5rem;
            background: #f4f4f4;
            position: fixed;
            z-index: 1100;
            top:0.02rem
        }
}
    .branditem{
        background:#fff;margin-top: 0.3rem;padding-top: 0.1rem;
        .branditem_hd{
            height:1.2rem;background:#f4f4f4;margin-bottom: 0.8rem;border-top:1px solid #e8e8e8;position:relative;
            .pic{position:absolute;left: 0.2rem;width:1.82rem;height:1.6rem;border:1px solid #e8e8e8;overflow:hidden;border-radius:0.1rem;z-index:2;}
            .goodslist{
                position:absolute;right: 0;overflow-x: scroll;max-width:5.4rem;white-space: nowrap;z-index:2;
                .dpic{margin: 0.2rem 0.2rem 0;width: 0.4rem;display: inline-block;}
                .curdpic{margin: 0;width: 0.9rem;height: 1.7rem;border: 1px solid #cecece;padding: 0 0.2rem 0;display: inline-block;background: #f5f5f5;}
            }
            .picbg{position:absolute;left: 1.84rem;right: 0;z-index:1;}
            
        }
        .branditem_bd{
            position:relative;min-height: 1rem;
            .branditem_bd_f{position:absolute;right: 0;left:0;bottom:0;text-align: center;color: #fff;line-height:0.8rem;}
        }
    }
    .swipercon{background:#fff;overflow:hidden;padding-bottom: 0.4rem;}
    .hdswiper{
        img{width: 100%;}
    }
    .swiper {
        width: 100%;height: 100%;padding-bottom: 1rem;padding-top: 0.2rem;
        .swiper-slide {
            background: #fff;height: 5rem;box-shadow: 0 0.05rem 0.5rem rgba(0, 0, 0, 0.1);opacity: 0;
            a{
                position: relative;width: 100%;height: 100%;display: block;
                .pic{
                    height: 3.2rem;width: 100%;display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    img{height: 100%;width: auto;}
                }
                .ename{text-align: center;font-size: 0.3rem;letter-spacing:0.08rem;padding: 0 0.1rem;}
                .ename:first-letter{font-size: 0.4rem;}
                .name{text-align: center;padding: 0 0.1rem;}
                .desc{text-align: center;padding: 0 0.1rem;font-size: 0.16rem;color:#666;}
                .afooter{position: absolute;left: 0.2rem;right: 0.2rem;bottom:0;text-align: center;line-height: 0.6rem;height: 0.6rem;font-size: 0.2rem;border-top:1px solid #eee;}
            }
        }
        .swiper-slide-active, .swiper-slide-prev, .swiper-slide-next{opacity: 1!important;}
    }
    .gallery-thumbs {
      height: 20%;
      box-sizing: border-box;
      padding: 0.2rem 0 0;
    }
    .gallery-thumbs .swiper-slide {
      width: 20%;
      height: 100%;
      opacity: 0.4;
    }
    .gallery-thumbs .swiper-slide-prev{
        opacity: 0.4!important;
    }
    .gallery-thumbs .swiper-slide-next{
        opacity: 0.4!important;
    }
    .gallery-thumbs .swiper-slide-active {
      opacity: 1;
      margin-top: -0.2rem;
    }
</style>